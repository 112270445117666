import * as React from 'react'
import loadable from '@loadable/component'

const StandardPage = loadable(() => import('/src/templates/StandardPage'))

const Page = ({ postInfo }) => {
    const post = {
        title: 'Terms & Conditions',
        content: `<div class="row" style="margin-top: 20px;" ><div class="col-lg-10 offset-lg-1"><h2>Important information about our services</h2>
<ol class="c-ordered-list">
<li><strong>Who are we and what do we do?<br>
</strong>CompareTheQuote.co.uk is a trading style of Protect Line Ltd. Protect Line is authorised and regulated by the Financial Conduct Authority (FCA). The FCA is the independent regulator of financial services in the United Kingdom. Protect Line Ltd is entered on the Financial Services Register (www.fca.org.uk/register) under reference 942467.</li>
<li><strong>Who is Protect Line?<br>
</strong>Protect Line Ltd is an insurance intermediary offering a range of pure protection products to customers based in the UK.</li>
<li><strong>What can we do for you?</strong><br>
The service we provide is to act on your behalf for the purpose of arranging the following type of insurance products: Pure Protection Products, for example Life Insurance, Critical Illness Cover and Income Protection; You will not receive advice or a recommendation from us in respect of the service described above. We may ask you some questions to narrow down the selection of insurance products that we will provide details on, but you will then need to make your own choice about how to proceed. Ultimately, whatever you choose to do we will act in your best interests at all times.</li>
<li>Further details about the scope of our services, how we get paid for them and our standard Terms of Business can be found in the pages below.</li>
<li>For your own benefit and protection, please read the terms and scope of our service carefully and ask for clarification if you do not understand anything.</li>
</ol>
<h2>Information about our insurance services</h2>
<h3>Pure Protection Products</h3>
<p>We offer products from a range of insurers for Term Assurance, Critical Illness Cover.</p>
<p>For Whole-Of-Life insurance, we offer cover from one provider only.</p>
<p>For Non-Medical (Over-50s) we offer cover from two providers.</p>
<p>For Income Protection we offer cover from one provider only.</p>
<p>The products we offer are non-profit and do not have any investment element, nor do they have any cash in value. Should you want cover with an investment element we would direct you to seek independent financial advice.</p>
<h3>How do we charge for our insurance services?</h3>
<p>We do not charge a fee for arranging an insurance product. We will receive commission from the insurer and this is reflected in the premium amount you pay to them. The amount of commission we receive will vary depending on the type of contract, term of the cover and the premium amount. Upon request, we will tell you how much commission we expect to receive should we arrange an insurance product on your behalf. You will receive a quotation which will tell you about any other fees relating to any particular insurance product we arrange for you.</p>
<h2>What happens if you have a complaint?</h2>
<p>If you are unhappy with the service you have received or a product we have arranged on your behalf, a copy of our complaints procedure, which sets out how we will handle your complaint, is available upon request. If you would like to make a complaint please contact us either:</p>
<p>In writing: <strong>Complaints, Protect Line Ltd, Suite 6, Bourne Gate, 25 Bourne Valley Road, Poole BH12 1DY</strong>;<br>
By e-mail: <a href="mailto:complaints@protectline.co.uk">complaints@protectline.co.uk</a>;<br>
By telephone: <a href="tel:03338803030">0333 880 3030</a></p>
<p>If you require further information about the Financial Ombudsman Service you can contact them directly:<br>
Tel: <a href="tel:08000234567">0800 0234567</a><br>
Website: <a href="https://www.financial-ombudsman.org.uk/" target="_blank" rel="noopener">www.financial-ombudsman.org.uk</a></p>
<h2>Providers we use</h2>
<p>Our panel of insurers for Term Assurance, Critical Illness Cover includes:</p>
<ul class="c-unordered-list">
<li>AIG</li>
<li>Aviva</li>
<li>HSBC</li>
<li>Legal &amp; General</li>
<li>LV=</li>
<li>Scottish Widows</li>
<li>VitalityLife</li>
<li>Zurich</li>
</ul>
<p>Whole-Of-Life insurance is provided by <strong>VitalityLife</strong>.</p>
<p>Non-Medically underwritten cover is provided by&nbsp;<strong>OneFamily</strong>&nbsp;and/or&nbsp;<strong>National Friendly</strong>.</p>
<p>Income Protection cover is provided by&nbsp;<strong>Holloway Friendly</strong>.</p>
<h2>Are we covered by the Financial Services Compensation Scheme?</h2>
<p>Yes, we are covered by the FSCS. You may be entitled to compensation from the scheme if we are unable to meet our obligations. This will depend on the type of business and the circumstances of the claim. The FSCS only pays compensation for financial loss and the limits are per person per firm, and per claim category, as listed below:</p>
<p>Long Term Insurance Contracts: Long term insurance contracts (e.g. a life insurance, critical illness or income protection policy) are covered for 100% of the claim without upper limit. Further information about the FSCS is available from their website:&nbsp;<a href="https://www.fscs.org.uk/" target="_blank" rel="noopener">www.fscs.org.uk</a></p>
<h2>Our terms of business</h2>
<p>These Terms of Business are made between Protect Line Ltd, Suite 6 Bourne Gate, 25 Bourne Valley Road, Poole, Dorset, BH12 1DY. Registered in England &amp; Wales with company number 7059779 (“<em>we, us, our, firm</em>”) and the client (“<em>you, your</em>”)</p>
<p>These are <em>our</em> standard Terms of Business under which <em>we</em> will arrange insurance for <em>you</em> which <em>we</em> intend to rely upon and will come into force with immediate effect. For <em>your</em> own benefit and protection please read these terms carefully and ask for clarification if <em>you</em> do not understand anything.</p>
<h3>Your Protection</h3>
<p>We accept instructions via telephone or e-mail. We may refuse at our discretion to accept certain instructions, although such discretion will not be exercised unreasonably.</p>
<p>We do not accept payments of any kind. Any payments should be made payable to the product providers only.</p>
<p>We will register the product in your name and you will be the owner and beneficiary of the product. Where a formal property and financial affairs Power of Attorney is in place, we will act in accordance with this when we are notified of, and provided with a full certified copy of the arrangement. If you reside in Scotland, England or Wales the certified copy will need to be provided by the Office of the Public Guardian. If you reside in Northern Ireland the certified copy will need to be provided by the Office of Care and Protection .</p>
<p>We will write to you if we become aware of a conflict of interest for example where our interests or those of another client become conflicted with your interests, and ask for your consent prior to proceeding with your instructions.</p>
<p>Unless otherwise agreed in writing we will not be obliged to review in the future any insurance product that we have arranged for you.</p>
<p>We will let you know if we receive any form of benefit in connection with the services we provide to you. Please note, in regards to certain historic policies, certain product providers may make payments to Tenet Group LTD (TGL) or Sesame Group in relation to commercial services that TGL or Sesame provided to them, but none of these payments relate to volumes of business placed with such product providers, or connected to individual transactions.</p>
<p>Where we are required to verify your identity as part of our regulatory requirements, we will retain your records both during and after the provision of our services to you. Where we use an electronic system to verify your identity, this will not affect your credit reference or your ability to obtain credit.</p>
<h3>What information do we hold about you and what do we do with it?</h3>
<p>We will collect and store your personal and financial information which will be handled in accordance with the Data Protection Act 2018. We will also collect information from third parties such as employers, credit reference agencies, fraud prevention agencies and other organisations that may include sensitive personal data about your health, ethnic origin or criminal prosecutions.</p>
<p>We will use your personal information to provide our services to you and this may require us to pass your personal information to product providers or other financial firms. We will use your personal information to inform you about similar products or services that we provide, unless you have told us otherwise.</p>
<p>If&nbsp;<em>you</em>&nbsp;no longer wish to receive such information from&nbsp;<em>us</em>,&nbsp; <a href="/contact-us/opt-out/">please complete the opt-out form</a>.</p>
<p>Upon receipt our records will be updated to reflect your request.</p>
<p>We may be required to pass on your personal information to the Financial Conduct Authority (FCA), Financial Ombudsman Service (FOS) or the Financial Services Compensation Scheme (FSCS) to help with an investigation or complaint or other authorities as required by law.</p>
<p>You have the right to access information we hold about you by making a request to us at the address detailed above.</p>
<h3>Other important terms</h3>
<p>Third party rights under the Contracts (Rights of Third Parties) Act 1999 are excluded.</p>
<p>These Terms of Business shall be governed by and construed in all respects in accordance with the Laws in England &amp; Wales and each party hereby submits to the exclusive jurisdiction of the courts of England &amp; Wales.</p>
<p>&nbsp;</p>
<h2>Previous Versions</h2>
<p><a title="Prior to 7th December 2023" href="/Terms-Conditions-Protect-Line-2023-12-06.pdf">Prior to 7th December 2023</a></p>
<p><a title="Prior to 15th December 2023" href="/Terms-Conditions-Protect-Line-2023-12-14.pdf">Prior to 15th December 2023</a></p>
<p><a title="Prior to 1st January 2024" href="/Terms-Conditions-Protect-Line-2024-12-31.pdf">Prior to 1st January 2024</a></p>
<p><a title="Prior to 1st July 2024" href="/Terms-Conditions-CTQ-2024-07-01.pdf" >Prior to 1st July 2024</a></p>
</div></div>`
    }

    return <StandardPage postInfo={postInfo} post={post} />
}

export default Page

